.Invitation-details {
    background-color: #132033;
    /*min-height: 100vh;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .section-box {
    font-style: oblique;
    background-color: #223a5e;
    background-image: url("/src/resources/fondo-2.png");
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: bottom;
    width: 104%;
    height: 90%;
    text-align: center;
    border: 1px solid black;
    /*padding: 1em;*/
  }
  .bible {
    font-style: oblique;
    text-align: left;
    background-color: #132033;
  }
  .section-box-text {
    padding: 1em;
  }
  .blank-box {
    height: 2px;
  }

  

  .list {
    text-align: left;
    padding-left: 1em;
  }

  .photo {
    height: 100%;
    width: 100%;
  }

  main-photo {
    height: 100%;
    width: 100%;
  }
  @media (min-width: 992px) {
    .main-photo {
      height: 100%;
      width: 25%;
    }
  }

  .section-title {
    color: #7f98b8;
    font: arial;
    padding-left: 10px;
    margin-left: 10px;
    margin-bottom: 5px;
    
    margin-top: 5px;
  }

  .section-content {
    color: white;
    padding-left: 10px;
    margin-left: 10px;
  }

  .wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(2.5em, auto);
    padding-right: 2em;
    padding-left: 1em;
  }

  .day-digit {
    font-size: 2.5em;
    grid-column: 1;
    grid-row: 1 / 2;
  }

  .day-name {
    grid-column: 1;
    grid-row: 2 / 3;
  }

  .hour-digit {
    font-size: 2.5em;
    grid-column: 2;
    grid-row: 1 / 2;
  }
  .hour-name {
    grid-column: 2;
    grid-row: 2 / 3;
  }

  .min-digit {
    font-size: 2.5em;
    grid-column: 3;
    grid-row:  1 / 2;
  }

  .min-name {
    grid-column: 3;
    grid-row:  2 / 3;
  }

  .second-digit {
    font-size: 2.5em;
    grid-column: 4;
    grid-row:  1 / 2;
  }

  .second-name {
    grid-column: 4;
    grid-row:  2 / 3;
  }

  .icon-section {
    margin-bottom: 3em;
  }

  .icon-text {
    margin-bottom: 0rem;
  }

  .quoteAuthor {
    text-align: right;
  }

  .flags {
    text-align: right;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0px;
    grid-auto-rows: minmax(2.5em, auto);
    height: 2em;
  }

  .flag {
    height: 100%;
    cursor: pointer;
  }

  .custom-footer {
    font-size: 0.4em;
    color: #223a5e;
  }

